<template>
<div class="dict">
  <div style="padding: 20px 0 0px 20px">
    <MyBreadcrumb></MyBreadcrumb>
  </div>
  <div class="dict-cont">
    <div class="tab">
      <div class="tab-list">
        <div :class="dictId == item.id ? 'active' : ''" v-for="item in dictList" :key="item.id" @click="changeDict(item)">{{ item.name }}</div>
      </div>
<!--      <div class="tab-add" @click="addDict">+ 新增语言</div>-->
    </div>
    <div class="search">
      <Input v-model="keyword"
             @on-enter="changeKeyword"
             @on-search="changeKeyword"
             placeholder="请输入关键词搜索"
             clearable
             search
             :enter-button="true"
             style="width: 200px"
             class="mr10"
      />
      <Button type="primary" class="mr10" @click="downLoadTemplate">模版下载</Button>
      <Upload :action="constant.URL + '/uploadfile/upload'"
              :data="{
                      type:0
                    }"
              accept=".xlsx,.xls"
              :headers="{
                      'access-token':Cookies.get(constant.tokenName)
                    }"
              :on-success="uploadFileSuccess"
              :show-upload-list="false"
      >
        <Button type="primary" class="mr10">批量导入</Button>

      </Upload>

      <Button type="primary" @click="addDictItem">添加语料</Button>
    </div>
    <div class="table">
      <Table border :columns="columns" :data="dataList" :loading="loading"></Table>
    </div>
    <div class="page">
      <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
    </div>
  </div>
  <!-- <Modal
      v-model="editModal"
      :title="curData.id ? '编辑' : '新增'"

  >
    <div>
      <Form ref="formCustom" :model="formCustom" :rules="ruleCustom" :label-width="80">
        <FormItem label="原文" prop="name">
          <Input type="textarea" :autosize="true" v-model="formCustom.name"></Input>
        </FormItem>
        <FormItem label="译文" prop="value">
          <Input type="textarea" :autosize="true" v-model="formCustom.value"></Input>
        </FormItem>
        <FormItem label="备注(选填)" prop="remarks">
          <Input type="textarea" :autosize="true" v-model="formCustom.remarks"></Input>
        </FormItem>
      </Form>

    </div>
    <div slot="footer">
      <Button @click="cancel">取消</Button>
      <Button type="primary" @click="editOk" >确定</Button>
    </div>
  </Modal> -->
  <Modal
      v-model="addDictModal"
      title="新增语言"
  >
    <div>
      <Form ref="addForm" :model="addForm" :rules="ruleAdd" :label-width="80">
        <FormItem label="名称" prop="name">
          <Input type="textarea" :autosize="true" v-model="addForm.name"></Input>
        </FormItem>
      </Form>
    </div>
    <div slot="footer">
      <Button @click="cancelAdd">取消</Button>
      <Button type="primary" @click="addOk" >确定</Button>
    </div>
  </Modal>
  <DeleModal :status="deleModalStatus" txt="确认删除吗？" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>
  <el-drawer :visible.sync="editModal" :size="800">
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">{{ curData.id ? '编辑' : '新增' }}</div>
      <div class="drawer-title-btns">
        <Button @click="cancel" style="margin-right: 10px;">取消</Button>
        <Button type="primary" @click="editOk" >确定</Button>
      </div>
    </div>
    <div style="padding: 20px;">
      <Form ref="formCustom" :model="formCustom" :rules="ruleCustom" :label-width="80">
        <FormItem label="原文" prop="name">
          <Input type="textarea" :autosize="true" v-model="formCustom.name"></Input>
        </FormItem>
        <FormItem label="译文" prop="value">
          <Input type="textarea" :autosize="true" v-model="formCustom.value"></Input>
        </FormItem>
        <FormItem label="备注(选填)" prop="remarks">
          <Input type="textarea" :autosize="true" v-model="formCustom.remarks"></Input>
        </FormItem>
      </Form>
    </div>
  </el-drawer>
</div>
</template>

<script>
import util from '@/utils/tools.js';
import DeleModal from '@/components/deleteModal.vue';
import Cookies from "js-cookie";
export default {
  name: "index",
  data(){
    return {
      Cookies:Cookies,
      keyword:'',
      columns:[
        {
          title: 'ID',
          key: 'id'
        },
        {
          title: '原文',
          key: 'name'
        },
        {
          title: '译文',
          key: 'value'
        },
        {
          title: '备注',
          key: 'remarks'
        },
        {
          title: '创建日期',
          key: 'name',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.created_at * 1000), 'MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: '操作',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small',
                  disabled:params.row.status == 2 ? true : false
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.edit(params.row)
                  }
                }
              }, '编辑'),
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small',
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.dele(params.row)
                  }
                }
              }, '删除'),
            ]);
          }
        },
      ],
      dataList:[],
      loading:false,
      total:0,
      page:1,
      pageSize:10,
      dictId:'1',
      curData:{},
      editModal:false,
      formCustom: {
        name: '',
        value: '',
        remarks: ''
      },
      ruleCustom: {
        name: [
          { required: true, message: '请输入原文', trigger: 'blur'  }
        ],
        value: [
          { required: true, message: '请输入译文', trigger: 'blur' }
        ],
      },
      deleModalStatus:false,
      modalLoading:false,
      dictList:[],
      addDictModal:false,
      addForm:{
        name:'',
      },
      ruleAdd: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur'  }
        ],
      },
      templateUrl:'',
    }
  },
  components:{
    DeleModal
  },
  created(){
    this.getDictList();
  },
  methods:{
    getDictList(type){
      this.api.dict.dictList().then((res)=>{
        this.dictList = res.list;
        if(!type){
          this.dictId = this.dictList[0].id;
          this.getList();
        }
      })
    },
    changeDict(data){
      this.dictId = data.id;
      this.page = 1;
      this.getList();
    },
    changeKeyword(){
      this.page = 1;
      this.getList();
    },
    getList(){
      let params = {
        dict_id:this.dictId,
        keyword:this.keyword,
        page:this.page,
        pageSize:this.pageSize
      };
      this.loading = true;
      this.api.dict.dictItemList(params).then((res)=>{
        if(this.page > 1 && !res.list.length){
          this.page = this.page - 1;
          this.getList();
        }else{
          this.dataList = res.list;
          this.total = +res.count;
        }

        this.loading = false;
        this.templateUrl = res.import_template_url;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getList();
    },
    addDictItem(){
      this.curData = {};
      this.formCustom.name = '';
      this.formCustom.value = '';
      this.formCustom.remarks = '';
      this.editModal = true;
    },
    edit(data){
      this.curData = data;
      this.formCustom.name = this.curData.name;
      this.formCustom.value = this.curData.value;
      this.formCustom.remarks = this.curData.remarks;
      this.editModal = true;

    },
    dele(data){
      this.curData = data;
      this.deleModalStatus = true;
    },
    cancel(){
      this.editModal = false;
    },

    editOk(){
      this.$refs.formCustom.validate((valid) => {
        if (valid) {
          if(this.curData.id){
            let params = {
              dict_id:this.dictId,
              name:this.formCustom.name,
              value:this.formCustom.value,
              remarks:this.formCustom.remarks,
              id:this.curData.id,
            };

            this.api.dict.dictItemUpdate(params).then((res)=>{
              this.$Message.success('操作成功');
              this.editModal = false;
              this.getList();
            })
          }else{
            let params = {
              dict_id:this.dictId,
              name:this.formCustom.name,
              value:this.formCustom.value,
              remarks:this.formCustom.remarks
            };

            this.api.dict.dictItemCreate(params).then((res)=>{
              this.$Message.success('操作成功');
              this.editModal = false;
              this.getList();
            })
          }

        }
      })

    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let params = {
        dict_id:this.dictId,
        id:this.curData.id,
      };
      this.api.dict.dictItemDelete(params).then((res)=>{
        this.$Message.success('操作成功');
        this.deleModalStatus = false;
        this.getList();
      })
    },
    cancelAdd(){
      this.addDictModal = false;
    },
    addOk(){
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          let params = {
            name:this.addForm.name,
          };
          this.api.dict.dictCreate(params).then((res)=>{
            this.$Message.success('操作成功');
            this.addDictModal = false;
            this.getDictList('add');
          })
        }
      })
    },
    addDict(){
      this.addDictModal = true;
      this.addForm.name = '';
    },
    downLoadTemplate(){
      window.open(this.templateUrl,'_blank');
    },
    uploadFileSuccess(response,file, fileList){  //上传文件成功
      let params = {
        dict_id:this.dictId,
        uploadfile_id:response.data.info.id
      }
      this.api.dict.dictItemImport(params).then((res)=>{
        this.page = 1;
        this.getList();
      })
    },
  }
}
</script>

<style scoped lang="scss">
.dict{
  font-size: 14px;
  .dict-cont{
    padding: 20px;
    margin: 20px;
    background-color: #FFFFFF;

    .tab{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .tab-list{
        //margin-right: 20px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        >div{
          margin-right: 10px;
          padding: 5px 15px;
          background: #F8F8F9;
          font-weight: bold;
        }
        .active{
          background: #4A79FF;
          color:#FFFFFF;
        }
      }
      .tab-add{
        padding: 5px 15px;
        background: #F8F8F9;
      }
    }
    .search{
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .mr10{
      margin-right: 10px;
    }
    .table{
      margin-top: 20px;
    }
    .page{
      margin-top: 20px;
      text-align: right;
    }
  }
}
</style>
<style>
.ivu-input-search:hover{
  border-color:#4A79FF !important;
  background-color:#4A79FF !important;
  background: #4A79FF !important;
}
.ivu-input-search{
  border-color:#4A79FF !important;
  background-color:#4A79FF !important;
  background: #4A79FF !important;
}
</style>
